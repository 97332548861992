import React, { ChangeEvent, FC } from "react";
import { HelpText, Input, LabelContainer, TextFieldContainer, Wrapper, LabelSpan, PreFieldContainer } from "./elements";

type TextFieldProps = {
  label?: string;
  placeholder?: string;
  value?: string | number;
  type?: "text" | "number";
  disabled?: boolean;
  error?: boolean;
  optional?: boolean;
  prefield?: boolean;
  inputmode?: string;
  helpText?: string | number;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
};

const TextField: FC<TextFieldProps> = ({
  label,
  placeholder,
  helpText,
  error,
  disabled,
  type,
  value,
  onChange,
  optional,
  prefield,
  inputmode,
}) => {
  const initialType = type ? type : "text";
  const placeholderText = placeholder ? placeholder : "";

  return (
    <TextFieldContainer>
      <LabelContainer>
        {label?.toUpperCase()}
        <LabelSpan>{optional ? " (optionnel)" : null}</LabelSpan>
      </LabelContainer>
      <Wrapper error={error} disabled={disabled}>
        {prefield ? <PreFieldContainer>+33(0)</PreFieldContainer> : null}
        <Input
          name={label}
          type={initialType}
          placeholder={placeholderText}
          disabled={disabled}
          value={value}
          onChange={onChange}
          prefield={prefield}
          inputmode={inputmode}
        />
      </Wrapper>
      {!!helpText ? <HelpText error={error}>{helpText}</HelpText> : null}
    </TextFieldContainer>
  );
};

export default TextField;
