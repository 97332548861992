import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/default";

type LogoProps = {
  src: string;
};

const BurgerImage = styled.img<LogoProps>`
  width: 100%;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 23px;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  width: 250px;
  height: 100vh;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primary};
  position: fixed;
  z-index: 999;
  right: 0;
  top: 0;
  padding: 40px 8px;
`;

const LinksList = styled.div``;

const LinkItem = styled(Link)`
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textWhite};
`;

export { CloseButton, MenuWrapper, BurgerImage, LinksList, LinkItem };
