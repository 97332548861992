import React from "react";
import { BurgerImage, CloseButton, LinksList, LinkItem, MenuWrapper } from "./elements";
import Image from "../../icons/default/index";

type MenuProps = {
  closeMenu: () => void;
};

const Menu: React.FC<MenuProps> = ({ closeMenu }) => {
  return (
    <MenuWrapper>
      <CloseButton onClick={closeMenu}>
        <BurgerImage src={Image["close"]} />
      </CloseButton>
      <LinksList>
        <LinkItem to="/users">users </LinkItem>
        <LinkItem to="/retailers">retailers </LinkItem>
      </LinksList>
    </MenuWrapper>
  );
};

export default Menu;
