import React, { FC } from "react";
import ReactLoader from "react-loader-spinner";
import styled from "styled-components";

const StyledLoader = styled(ReactLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type LoaderProps = {
  children?: any;
  isLoading: boolean;
};

const Loader: FC<LoaderProps> = (props) => {
  return (
    <>
      {props.isLoading ? (
        <StyledLoader type="TailSpin" color={"#000"} height={100} width={100} timeout={0} />
      ) : (
        props.children
      )}
    </>
  );
};

export default Loader;
