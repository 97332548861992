import React, { FC } from "react";
import { ButtonContainer, ButtonContainerProps } from "./elements";

type ButtonProps = ButtonContainerProps & {
  children: any;
  onClick(): void;
};

const Button: FC<ButtonProps> = ({ disabled, onClick, children }) => {
  return (
    <ButtonContainer onClick={onClick} disabled={disabled}>
      {children}
    </ButtonContainer>
  );
};

export default Button;
