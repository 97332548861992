import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../config/theme/default";
import { Container, Row, Col } from "styled-bootstrap-grid";

const ListTitleContainer = styled.div`
  display: none;
  padding: 42px 0 0 17px;
  margin-bottom: 30px;
  border-top: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.primary};

  ${media.lg`
		display:flex;
	`};
`;

const TitleText = styled.div`
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
`;

const ListTitle = () => {
  return (
    <ListTitleContainer>
      <Container fluid>
        <Row>
          <Col col lg={1}>
            <TitleText>DATE</TitleText>
          </Col>
          <Col col lg={1}>
            <TitleText>FIRSTNAME</TitleText>
          </Col>
          <Col col lg={1}>
            <TitleText>LASTNAME</TitleText>
          </Col>
          <Col col lg={2}>
            <TitleText>EMAIL</TitleText>
          </Col>
          <Col col lg={1}>
            <TitleText>PHONE</TitleText>
          </Col>
          <Col col lg={2}>
            <TitleText>ADDRESS</TitleText>
          </Col>
          <Col col lg={1}>
            <TitleText>CITY</TitleText>
          </Col>
          <Col col lg={1}>
            <TitleText>COUNTRY</TitleText>
          </Col>
          <Col col lg={1}>
            <TitleText>VEHICLE</TitleText>
          </Col>
          <Col col lg={1}>
            <TitleText>PAYMENT STATUS</TitleText>
          </Col>
        </Row>
      </Container>
    </ListTitleContainer>
  );
};

export default ListTitle;
