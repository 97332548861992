import React from "react";
import styled from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../../config/theme/default";
import { Col } from "styled-bootstrap-grid";

const HiddenCard = styled(Col)`
  flex-direction: column;
  ${media.lg`
		display: none;		
`};
`;
const HiddenUserText = styled(Col)`
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  text-transform: uppercase;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
`;
const HiddenElement = styled(Col)`
  margin-bottom: 10px;
`;

const HiddenCardItem = () => {
  return (
    <HiddenCard lg={3} md={5} col={5}>
      <HiddenElement col={12} sm={12}>
        <HiddenUserText>SEND DATE</HiddenUserText>
      </HiddenElement>
      <HiddenElement col={12} sm={12}>
        <HiddenUserText>NAME</HiddenUserText>
      </HiddenElement>
      <HiddenElement col={12} sm={12}>
        <HiddenUserText>EMAIL</HiddenUserText>
      </HiddenElement>
      <HiddenElement col={12} sm={12}>
        <HiddenUserText>SHOP</HiddenUserText>
      </HiddenElement>
      <HiddenElement col={12} sm={12}>
        <HiddenUserText>Seller</HiddenUserText>
      </HiddenElement>
    </HiddenCard>
  );
};

export default HiddenCardItem;
