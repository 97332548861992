import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Login from "../pages/login";
import RetailersPage from "../pages/retailers";
import UserPage from "../pages/users/index";

export default function Root() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/users" component={UserPage} />
        <Route path="/retailers" component={RetailersPage} />

        <Redirect exact from="/" to="/login" />
      </Switch>
    </Router>
  );
}
