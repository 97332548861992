// import { ApolloLink, HttpLink, InMemoryCache } from "apollo-boost";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import constants from "../../config/constants";
const cache = new InMemoryCache();

const link = new HttpLink({ uri: constants.apiUrl });

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("AUTH_TOKEN");
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    },
  };
});

const client = new ApolloClient({
  cache,
  name: "AssurLink",
  link: ApolloLink.from([authLink, link]),
});

export default client;
