import { format } from "date-fns";

export const DATE_FORMAT = "dd/MM/yyyy";

const formatStoredDate = (dateValue: Date, dateFormat?: string): string => {
  const toFormat = dateFormat ? dateFormat : DATE_FORMAT;
  return format(new Date(dateValue), toFormat);
};

export default formatStoredDate;
