import styled, { css } from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../config/theme/default";
import { HexToRgba } from "../../helpers/index";

export type ButtonContainerProps = {
  disabled?: boolean;
};

const disabledButtonStyles = css`
  box-shadow: unset;
  background-color: ${(props: StyledThemePropsType) => HexToRgba(props.theme.colors.primary, 0.5)};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textWhite};
  border: none;
  cursor: unset;
`;

const ButtonContainer = styled.button<ButtonContainerProps>`
  width: 100%;
  text-transform: uppercase;
  padding: 14px 17px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primary};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textWhite};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  outline: none;
  ${({ disabled }: ButtonContainerProps) => (disabled ? disabledButtonStyles : null)}

  &:hover {
    background-color: ${(props: ButtonContainerProps & StyledThemePropsType) =>
      props.disabled ? HexToRgba(props.theme.colors.primary, 0.5) : props.theme.colors.actionHover};
  }

  ${media.lg`
		padding: 19px 0;
	`};
`;

export { ButtonContainer };
