import React, { useCallback, useEffect, useState } from "react";
import ListTitle from "./components/ListTitle";
import UsersContainer from "./view";
import {
  ListWrapper,
  Logo,
  LogoWrapper,
  PageContainer,
  Title,
  RetailerWrapper,
  BurgerImage,
  BurgerButton,
} from "./elements";
import SearchInput from "../../shared/elements/Search";
import Image from "../../shared/icons/default/index";
import { useQuery } from "@apollo/client";
import { Loader } from "../../shared/elements";
import Menu from "../../shared/elements/Menu";
import GET_VOUCHERS from "../../api/queries/vouchers";
import { GetVouchersResult, GetVouchersVariables } from "../../api/interfaces/vouchers";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { clearStore, NOT_AUTHORIZED } from "../../routing/variables";
import formatStoredDate from "../../shared/helpers/formatStoredDate";

const RetailersPage = () => {
  const history = useHistory();
  const token = localStorage.getItem("AUTH_TOKEN");
  const [search, setSearch] = useState<string>("");
  const [dataCsv, setDataCsv] = useState([]);
  const { data, loading, error } = useQuery<GetVouchersResult, GetVouchersVariables>(GET_VOUCHERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      // add pagination
      pagination: { page: 1, perPage: 100 },
      search: search || undefined,
    },
  });
  const { data: dataForCSV, loading: loadingCsv } = useQuery<GetVouchersResult, GetVouchersVariables>(GET_VOUCHERS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (loadingCsv) return;
    const formatedData = Object.values(dataForCSV.vouchers).reduce((acc, el: any) => {
      const dataEl = {
        date: formatStoredDate(new Date(el.generatedAt)),
        retailer: el.distributorName,
        customer: el.email,
        shop: el.storeName,
        seller: el.vendorName,
        fullName: `${el.firstName} ${el.lastName}`,
        mobile: el.mobile,
        productType: el.productType,
      };
      return [...acc, dataEl];
    }, []);
    setDataCsv(formatedData);
  }, [dataForCSV, loadingCsv]);

  const [burgerOpened, setBurgerOpened] = useState<boolean>(false);

  const openBurgerMenu = useCallback(() => {
    setBurgerOpened(!burgerOpened);
  }, [setBurgerOpened, burgerOpened]);

  const closeBurgerMenu = useCallback(() => {
    setBurgerOpened(false);
  }, [setBurgerOpened]);

  const redirectToLogin = useCallback(() => {
    clearStore();
    history.push("/login");
  }, [history]);

  useEffect(() => {
    if (!token) return redirectToLogin();
    error?.graphQLErrors.forEach((err) => {
      if (err.extensions.code === NOT_AUTHORIZED) return redirectToLogin();
    });
  }, [error, history, redirectToLogin, token]);

  const headersCSV = [
    { label: "SEND DATE", key: "date" },
    { label: "FULL NAME", key: "fullName" },
    { label: "RETAILER", key: "retailer" },
    { label: "CUSTOMER", key: "customer" },
    { label: "CUSTOMER PHONE", key: "mobile" },
    { label: "SHOP", key: "shop" },
    { label: "SELLER", key: "seller" },
    { label: "SELLER", key: "seller" },
    { label: "TYPE OF VEHICULE", key: "productType" },
  ];

  return (
    <PageContainer>
      {burgerOpened && <Menu closeMenu={closeBurgerMenu} />}
      <RetailerWrapper>
        <LogoWrapper>
          <Logo src={Image["logo"]} />
          <BurgerButton onClick={openBurgerMenu}>
            <BurgerImage src={Image["burgerMenu"]} />
          </BurgerButton>
        </LogoWrapper>
        <ListWrapper>
          <Title>Retailers page</Title>
          {!!dataCsv.length ? (
            <CSVLink data={dataCsv} headers={headersCSV}>
              Download data
            </CSVLink>
          ) : null}
          <SearchInput placeholder="Search" onSearch={setSearch} value={search} />
        </ListWrapper>
        <ListTitle />
        <Loader isLoading={loading}>
          <UsersContainer vouchersData={data} />
        </Loader>
      </RetailerWrapper>
    </PageContainer>
  );
};

export default RetailersPage;
