import React from "react";
import { Container, Row } from "styled-bootstrap-grid";
import formatStoredDate from "../../shared/helpers/formatStoredDate";
import HiddenCardItem from "./components/userContainer/HiddenCardItem";
import { Card, CardBody, CardItem, UserText, Element, Wrapper, UserCardWrapper } from "./elements";

type UsersContainerProps = {
  usersData: any;
};

const UsersContainer = ({ usersData }: UsersContainerProps) => {
  return (
    <Wrapper>
      <UserCardWrapper>
        <Container fluid>
          <Row>
            {usersData?.users.map(({ insuranceRequest: user, paymentStatus }, index) => {
              return (
                <Card xl={12} lg={12} md={6} sm={12} key={index}>
                  <CardBody>
                    <HiddenCardItem />
                    <CardItem lg={12} md={7} col={7}>
                      <Element col={12} lg={1}>
                        <UserText>{formatStoredDate(new Date(user.effectiveDate))}</UserText>
                      </Element>
                      <Element col={12} lg={1}>
                        <UserText>{user.firstName}</UserText>
                      </Element>
                      <Element col={12} lg={1}>
                        <UserText>{user.lastName}</UserText>
                      </Element>
                      <Element col={12} lg={2}>
                        <UserText>{user.email}</UserText>
                      </Element>
                      <Element col={12} lg={1}>
                        <UserText>{user.mobile}</UserText>
                      </Element>
                      <Element col={12} lg={2}>
                        <UserText>{user.address}</UserText>
                      </Element>
                      <Element col={12} lg={1}>
                        <UserText>{user.city}</UserText>
                      </Element>
                      <Element col={12} lg={1}>
                        <UserText>{user.country}</UserText>
                      </Element>
                      <Element col={12} lg={1}>
                        <UserText> {user.productType.name} </UserText>
                      </Element>
                      <Element col={12} lg={1}>
                        <UserText>{paymentStatus}</UserText>
                      </Element>
                    </CardItem>
                  </CardBody>
                </Card>
              );
            })}
          </Row>
        </Container>
      </UserCardWrapper>
    </Wrapper>
  );
};

export default UsersContainer;
