const gridTheme = {
  gridColumns: 12,
  breakpoints: {
    lg: 1152,
    md: 768,
    xs: 320,
  },
  row: {
    padding: 0,
  },
  col: {
    padding: 0,
  },
  container: {
    padding: 0,
    maxWidth: {
      lg: 1000,
      md: 538,
      xs: 290,
    },
  },
};

export default gridTheme;
