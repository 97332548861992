import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../config/theme/default";
import { Col, Row } from "styled-bootstrap-grid";

type LogoProps = {
  src: string;
};

const RetailersWrapper = styled.div`
  padding-top: 20px;
`;

const Logo = styled.img<LogoProps>`
  width: 180px;
  height: 48px;
`;

const BurgerImage = styled.img<LogoProps>`
  width: 100%;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 23px;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
`;

const BurgerButton = styled.div`
  height: 14px;
  width: 20px;
  position: absolute;
  right: 0;
  cursor: pointer;
`;

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;
const RetailerWrapper = styled.div`
  position: relative;
  max-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 46px 15px 0;
`;

const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const ContentWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  display: flex;
`;
const RetailerCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${media.md`
		flex-direction: row;
	`};
  ${media.lg`
		flex-direction: column;
	`};
`;
const Card = styled(Col)`
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  ${media.md`
	margin-bottom: 30px;
`};
  ${media.lg`
		height: 68px;
		margin-bottom: 10px;
	`};
`;
const CardBody = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 345px;
  border-radius: 17px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.textWhite};
  border: 0.5px solid ${(props: StyledThemePropsType) => props.theme.colors.neutralMedium};
  box-sizing: border-box;
  padding: 24px 17px;

  ${media.lg`
		padding: 0 0 0 17px;
		width: 100%;
	`};
`;
const CardItem = styled(Col)`
  flex-direction: column;

  ${media.lg`
	display: flex;
	flex-direction: row;
`};
`;
const Element = styled(Col)`
  margin-bottom: 10px;
  display: flex;
  ${media.lg`
		margin-bottom: 0;
		align-items: center;
	`};
`;
const RetailerText = styled.h3`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.md`
		flex-direction: row;
		align-items: center;
		margin-bottom: 23px;
		padding: 0 17px;
	`};
  ${media.lg`
		padding: 0;
	`};
`;
const Title = styled.h2`
  font-size: 22px;
  line-height: 1.45;
  letter-spacing: 1.2px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.secondaryMain};
  margin: 0;
  ${media.md`
    margin: 0 30px 0 0;
  `};
  ${media.lg`
		margin-right: 50px;
	`};
`;

const MenuWrapper = styled.div`
  width: 250px;
  height: 100vh;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primary};
  position: absolute;
  z-index: 999;
  right: 0;
  top: 0;
`;

export {
  PageContainer,
  RetailerWrapper,
  RetailersWrapper,
  Logo,
  LogoWrapper,
  ContentWrapper,
  Card,
  CardBody,
  CardItem,
  Element,
  RetailerText,
  ListWrapper,
  Title,
  Wrapper,
  RetailerCardWrapper,
  BurgerImage,
  BurgerButton,
  MenuWrapper,
  CloseButton,
};
