import logo from "./logo.svg";
import search from "./search.svg";
import burgerMenu from "./burger_menu.svg";
import close from "./close.svg";

export type IconsType = {
  [key: string]: any;
};

const Icons: IconsType = {
  logo,
  search,
  burgerMenu,
  close,
};

export default Icons;
