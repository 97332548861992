import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { LoginContainer, Logo, LoginWrapper, LoginTitle, LoginTextWrapper, LogoWrapper } from "./elements";
import { TextField, Button } from "../../shared/elements";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { AuthorizeSchema } from "../../shared/validation/login";
import Image from "../../shared/icons/default/index";
import {
  AuthorizeFieldsType,
  AuthorizeFieldsTypeErrors,
  AuthorizeMutationResult,
  AuthorizeMutationVariables,
} from "../../api/interfaces/login";
import LOGIN from "../../api/mutation/login";

const initialAuthorizeErrors: AuthorizeFieldsTypeErrors = {
  email: "",
  password: "",
};

const Login = () => {
  localStorage.removeItem("AUTH_TOKEN");

  const initialAuthorize: AuthorizeFieldsType = {
    email: "",
    password: "",
  };

  const { push } = useHistory();
  const [authorize, setAuthorize] = useState<AuthorizeFieldsType>(initialAuthorize);
  const [authorizeErrors, setAuthorizeErrors] = useState<AuthorizeFieldsTypeErrors>(initialAuthorizeErrors);
  const [authorizeMutation] = useMutation<AuthorizeMutationResult, AuthorizeMutationVariables>(LOGIN);

  const inputsOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLInputElement>) => {
      const value: string = event.target.value;
      return setAuthorize((prev) => ({
        ...prev,
        [field]: value.trim(),
      }));
    },
    [setAuthorize]
  );

  const handlerLogIn = useCallback(async () => {
    const validationPassed = await AuthorizeSchema.validate({ ...authorize }, { abortEarly: false }).catch((e) => {
      const errors: AuthorizeFieldsTypeErrors = { ...initialAuthorizeErrors };
      e.inner.map((err: yup.ValidationError) => (errors[err.path] = err.message));
      return setAuthorizeErrors({ ...errors });
    });

    if (validationPassed) {
      setAuthorizeErrors(initialAuthorizeErrors);
      await authorizeMutation({ variables: { email: authorize.email, password: authorize.password } }).then(
        async ({ data }: ExecutionResult<AuthorizeMutationResult>) => {
          if (data) {
            localStorage.setItem("AUTH_TOKEN", data.login);
            return push("/users");
          }
        }
      );
    }
  }, [authorize, authorizeMutation, push]);

  const enableButton = useMemo(() => !!authorize.email && !!authorize.password, [authorize]);

  return (
    <LoginContainer>
      <LogoWrapper>
        <Logo src={Image["logo"]} />
      </LogoWrapper>
      <LoginWrapper>
        <LoginTextWrapper>
          <LoginTitle>Bonjour</LoginTitle>
          <TextField onChange={inputsOnChange("email")} label="email" error={!!authorizeErrors.email} />
          <TextField onChange={inputsOnChange("password")} label="password" error={!!authorizeErrors.password} />
          <Button onClick={handlerLogIn} disabled={!enableButton}>
            LOG IN
          </Button>
        </LoginTextWrapper>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default Login;
