import React, { ChangeEvent, useCallback } from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import Icon from "../../icons/default/index";
import { StyledThemePropsType } from "../../../config/theme/default";

type DefaultInputProps = {
  placeholder: string;
  value: string;
};

type SearchInputProps = {
  placeholder: string;
  onSearch(val: string): void;
  value: string;
};

const SearchInputWrapper = styled.div`
  width: 200px;
  height: 38px;
  margin: 25px 0 20px;
  position: relative;
  ${media.md`
    margin: 0 0 0 auto;
    flex-direction: row;
    align-items: center;
  `};
`;
const Input = styled.input<DefaultInputProps>`
  width: 100%;
  height: 38px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.textWhite};
  border: 0.5px solid ${(props: StyledThemePropsType) => props.theme.colors.neutralMedium};
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 13px;
  outline: none;

  ::-webkit-input-placeholder {
    color: ${(props: StyledThemePropsType) => props.theme.colors.neutralMedium};
  }
`;
const Image = styled.img`
  position: absolute;
  right: 16px;
  top: 10px;
`;

function SearchInput(props: SearchInputProps) {
  const { placeholder, onSearch, value } = props;

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const val = event.target.value;
      onSearch(val);
    },
    [onSearch]
  );

  return (
    <SearchInputWrapper>
      <Input onChange={onChange} value={value} placeholder={placeholder} />
      <Image src={Icon["search"]} />
    </SearchInputWrapper>
  );
}

export default SearchInput;
