import React, { useCallback, useEffect, useState } from "react";
import ListTitle from "./components/ListTitle";
import UsersContainer from "./view";
import {
  ListWrapper,
  Logo,
  LogoWrapper,
  PageContainer,
  Title,
  UserWrapper,
  BurgerImage,
  BurgerButton,
} from "./elements";
import SearchInput from "../../shared/elements/Search";
import Image from "../../shared/icons/default/index";
import { useQuery } from "@apollo/client";
import { GetUsersResult, GetUsersVariables } from "../../api/interfaces/users";
import GET_USERS from "../../api/queries/users";
import { Loader } from "../../shared/elements";
import Menu from "../../shared/elements/Menu";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { clearStore, NOT_AUTHORIZED } from "../../routing/variables";
import formatStoredDate from "../../shared/helpers/formatStoredDate";

const UserPage = () => {
  const history = useHistory();
  const token = localStorage.getItem("AUTH_TOKEN");
  const [search, setSearch] = useState<string>("");
  const [dataCsv, setDataCsv] = useState([]);

  const { data, loading, error } = useQuery<GetUsersResult, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      // add pagination
      pagination: { page: 1, perPage: 100 },
      search: search || undefined,
    },
  });

  const { data: dataForCSV, loading: loadingCsv } = useQuery<GetUsersResult, GetUsersVariables>(GET_USERS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (loadingCsv) return;
    const formatedData = Object.values(dataForCSV.users).reduce((acc, el: any) => {
      const {
        firstName,
        lastName,
        email,
        mobile,
        address,
        city,
        country,
        productType,
        effectiveDate,
      } = el.insuranceRequest;
      const dataEl = {
        date: formatStoredDate(new Date(effectiveDate)),
        firstName,
        lastName,
        email,
        mobile,
        address,
        city,
        country,
        vehicle: productType.name,
        status: el.paymentStatus,
      };
      return [...acc, dataEl];
    }, []);
    setDataCsv(formatedData);
  }, [dataForCSV, loadingCsv]);

  const [burgerOpened, setBurgerOpened] = useState<boolean>(false);

  const openBurgerMenu = useCallback(() => {
    setBurgerOpened(!burgerOpened);
  }, [setBurgerOpened, burgerOpened]);

  const closeBurgerMenu = useCallback(() => {
    setBurgerOpened(false);
  }, [setBurgerOpened]);

  const redirectToLogin = useCallback(() => {
    clearStore();
    history.push("/login");
  }, [history]);

  useEffect(() => {
    if (!token) return redirectToLogin();
    error?.graphQLErrors.forEach((err) => {
      if (err.extensions.code === NOT_AUTHORIZED) return redirectToLogin();
    });
  }, [error, history, redirectToLogin, token]);

  const headersCSV = [
    { label: "DATE", key: "date" },
    { label: "FIRSTNAME", key: "firstName" },
    { label: "LASTNAME", key: "lastName" },
    { label: "EMAIL", key: "email" },
    { label: "PHONE", key: "mobile" },
    { label: "ADDRESS", key: "address" },
    { label: "CITY", key: "city" },
    { label: "COUNTRY", key: "country" },
    { label: "VEHICLE", key: "vehicle" },
    { label: "PAYMENT STATUS", key: "status" },
  ];

  return (
    <PageContainer>
      {burgerOpened && (
        <Menu closeMenu={closeBurgerMenu} />
        // <MenuWrapper>
        //   <CloseButton onClick={closeBurgerMenu}>
        //     <BurgerImage src={Image["close"]} />
        //   </CloseButton>
        // </MenuWrapper>
      )}
      <UserWrapper>
        <LogoWrapper>
          <Logo src={Image["logo"]} />
          <BurgerButton onClick={openBurgerMenu}>
            <BurgerImage src={Image["burgerMenu"]} />
          </BurgerButton>
        </LogoWrapper>
        <ListWrapper>
          <Title>User page</Title>
          {!!dataCsv.length ? (
            <CSVLink data={dataCsv} headers={headersCSV}>
              Download data
            </CSVLink>
          ) : null}
          <SearchInput placeholder="Search" onSearch={setSearch} value={search} />
        </ListWrapper>
        <ListTitle />
        <Loader isLoading={loading}>
          <UsersContainer usersData={data} />
        </Loader>
      </UserWrapper>
    </PageContainer>
  );
};

export default UserPage;
