import gql from "graphql-tag";

const GET_VOUCHERS = gql`
  query vouchers($pagination: PaginationOptions, $search: String) {
    vouchers(pagination: $pagination, search: $search) {
      id
      orderId
      productName
      productType
      productSerial
      distributorName
      storeName
      vendorName
      firstName
      lastName
      mobile
      email
      productPrice
      isUsed
      generatedAt
    }
  }
`;

export default GET_VOUCHERS;
