import gql from "graphql-tag";

const GET_USERS = gql`
  query users($pagination: PaginationOptions, $search: String) {
    users(pagination: $pagination, search: $search) {
      id
      privacyPolicyConsent
      paymentStatus
      insuranceRequest {
        id
        effectiveDate
        firstName
        lastName
        email
        mobile
        address
        city
        country
        productModel
        productSerial
        productType {
          name
        }
      }
    }
  }
`;

export default GET_USERS;
