import React from "react";
import { Container, Row } from "styled-bootstrap-grid";
import formatStoredDate from "../../shared/helpers/formatStoredDate";
import HiddenCardItem from "./components/userContainer/HiddenCardItem";
import { Card, CardBody, CardItem, RetailerText, Element, Wrapper, RetailerCardWrapper } from "./elements";

type RetailersContainerProps = {
  usersData: any;
};

const RetailersContainer = ({ vouchersData }: any) => {
  return (
    <Wrapper>
      <RetailerCardWrapper>
        <Container fluid>
          <Row>
            {vouchersData?.vouchers.map((voucher: any) => {
              return (
                <Card xl={12} lg={12} md={6} sm={12} key={voucher.id}>
                  <CardBody>
                    <HiddenCardItem />
                    <CardItem lg={12} md={7} col={7}>
                      <Element col={12} lg={2}>
                        <RetailerText>{formatStoredDate(new Date(voucher.generatedAt))}</RetailerText>
                      </Element>
                      <Element col={12} lg={2}>
                        <RetailerText>{voucher.distributorName}</RetailerText>
                      </Element>
                      <Element col={12} lg={3}>
                        <RetailerText>{voucher.email}</RetailerText>
                      </Element>
                      <Element col={12} lg={3}>
                        <RetailerText>{voucher.storeName}</RetailerText>
                      </Element>
                      <Element col={12} lg={2}>
                        <RetailerText> {voucher.vendorName} </RetailerText>
                      </Element>
                    </CardItem>
                  </CardBody>
                </Card>
              );
            })}
          </Row>
        </Container>
      </RetailerCardWrapper>
    </Wrapper>
  );
};

export default RetailersContainer;
